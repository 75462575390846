import { useOnDGameAnalytics } from '../../../analytics/useOndGameAnalytics';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useLoadGame } from '../../../hooks/useLoadGame';
import { useIsCoordinator, useMyInstance } from '../../../hooks/useMyInstance';
import { type GamePackRequestedNotification } from '../../../types';
import { MediaUtils } from '../../../utils/media';
import { useGamePack } from '../../Game/GamePack/useGamePack';
import { useLocalLoadedGamePack } from '../../Game/GamePlayStore';
import { useOndGameState } from '../../Game/hooks';
import { usePostGameControlAPI } from '../../Game/PostGame/Provider';
import { useOndGameUIControl } from '../../OnDGameUIControl';
import { useParticipantByClientId } from '../../Player';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

function GamePackRequestedNotificationView(
  props: BaseNotificationProps & {
    notification: GamePackRequestedNotification;
  }
): JSX.Element | null {
  const { notification } = props;
  const { metadata } = notification;
  const me = useMyInstance();
  const isCoordinator = useIsCoordinator();
  const notificationDataSource = useNotificationDataSource();
  const loadGame = useLoadGame();
  const currentGamePack = useLocalLoadedGamePack();
  const requestedBy = useParticipantByClientId(metadata.requestedByClientId);
  const ctrl = usePostGameControlAPI();
  const ondUICtrl = useOndGameUIControl();
  const ondGameState = useOndGameState();
  const { data: gamePack, isLoading: isLoadingGamePack } = useGamePack(
    metadata.gamePackId
  );
  const analytics = useOnDGameAnalytics();

  const handleLoadExperience = useLiveCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!gamePack) return;
      analytics.trackRequestedExperienceLoaded({
        gamePackId: gamePack.id,
        gamePackName: gamePack.name,
      });
      if (ondGameState === 'ended' && me) {
        await ctrl.prependRecommendation(gamePack);
        await ctrl.selectRecommendedPack(gamePack);
        ondUICtrl?.onClickChooseNextGamePack(gamePack.id, {
          playHistoryTargetId: me.id,
          subscriberId: me.id,
        });
      } else {
        loadGame({ pack: metadata.gamePackId });
      }
      notificationDataSource.dismiss(notification.id);
    }
  );

  const handleClose = useLiveCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(notification.id);
  });

  if (
    !isCoordinator ||
    currentGamePack?.id === metadata.gamePackId ||
    isLoadingGamePack ||
    !gamePack ||
    (ondGameState && ondGameState !== 'ended') // don't show notification if game is in progress
  ) {
    return null;
  }

  const gamePackCover = MediaUtils.PickMediaUrl(gamePack.cover);
  return (
    <div
      className={`
        py-5 px-5 w-166
        flex items-center justify-between
        text-main-layer rounded-xl
        bg-gradient-to-tr from-yellow-start to-yellow-end
      `}
    >
      <div className='flex-1 flex items-center gap-4'>
        {gamePackCover && (
          <img
            src={gamePackCover}
            alt='Game Pack Cover'
            className='h-20 w-auto rounded-lg'
          />
        )}
        <div>
          <p className='font-bold'>
            {requestedBy?.firstName ?? requestedBy?.username ?? 'Someone'} wants
            to play <span className='underline'>{gamePack.name}</span>.
          </p>
          <p className='text-sms'>
            Click 'Load' if you want to load this experience.
          </p>
        </div>
      </div>

      <div className='flex-none flex flex-col gap-2.5 justify-between items-center'>
        <button
          type='button'
          className='w-32 h-10 btn-secondary'
          onClick={handleLoadExperience}
        >
          Load
        </button>
        <button
          type='button'
          className='text-2xs font-bold hover:underline'
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default GamePackRequestedNotificationView;
